import React from 'react';
// import Fade from 'react-reveal/Fade';

function Events() {
  return (
    <section className='events' id='events'>
      <div className='events__inner'>

        <div className='events__event'>

            <div className='events__left'>
              <div className='events__date'>
                18.01.2025
              </div>
              <div className='events__place'>
                EMOLAND — ПЕРВЫЙ ЭМО - <br/>ЦИОНАЛЬНЫЙ
              </div>
              <div className='events__city'>
                СПБ, Aurora
              </div>
            </div>

            <div className='events__right'>
              <div>
                <a href='https://vk.com/club228641480' className='button' target='_blank' rel='noopener noreferrer'>
                  ИНФО
                </a>
              </div>
              <div>
                <a href='https://ticketscloud.com/v1/widgets/common?token=eyJhbGciOiJIUzI1NiIsImlzcyI6InRpY2tldHNjbG91ZC5ydSIsInR5cCI6IkpXVCJ9.eyJwIjoiNWQ0NDIzY2ZhYjI2NzU1NzVmNDY5OGY4In0.zOVXyCGLZcvOTsnMgs_xpL-ZC1ptP8aazCcGORWiT3k&lang=ru&event=67599681bf6ee5df99f0e06a&s=1' className='button button--pink' target='_blank' rel='noopener noreferrer'>
                  БИЛЕТЫ
                </a>
              </div>
            </div>

        </div>
        {/*
        <div className='events__event'>

            <div className='events__left'>
              <div className='events__date'>
                03.08.2024
              </div>
              <div className='events__place'>
                EMOLAND FEST
              </div>
              <div className='events__city'>
                СПБ, Зелёная сцена
              </div>
            </div>

            <div className='events__right'>
              <div>
                <a href='https://vk.com/emolandfestspb' className='button' target='_blank' rel='noopener noreferrer'>
                  ИНФО
                </a>
              </div>
              <div>
                <a href='https://ticketscloud.com/v1/widgets/common?token=eyJhbGciOiJIUzI1NiIsImlzcyI6InRpY2tldHNjbG91ZC5ydSIsInR5cCI6IkpXVCJ9.eyJwIjoiNWQ0NDIzY2ZhYjI2NzU1NzVmNDY5OGY4In0.zOVXyCGLZcvOTsnMgs_xpL-ZC1ptP8aazCcGORWiT3k&lang=ru&event=665f6f070f087dc1f22c452c&s=1' className='button button--pink' target='_blank' rel='noopener noreferrer'>
                  БИЛЕТЫ
                </a>
              </div>
            </div>
        </div>
        */}
      </div>
      {/*<div className='events__two-buttons'>
        <a href='https://ticketscloud.com/v1/widgets/common?token=eyJhbGciOiJIUzI1NiIsImlzcyI6InRpY2tldHNjbG91ZC5ydSIsInR5cCI6IkpXVCJ9.eyJwIjoiNWQ0NDIzY2ZhYjI2NzU1NzVmNDY5OGY4In0.zOVXyCGLZcvOTsnMgs_xpL-ZC1ptP8aazCcGORWiT3k&lang=ru&event=665f73d2be09438c9c2925be&s=1' className='button button--pink button--padding' target='_blank' rel='noopener noreferrer'>
          БИЛЕТЫ НА 2 ГОРОДА
        </a>
      </div>*/}
    </section>
  )
}

export default Events;
